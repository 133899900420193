import React from "react";
import {
  SEO,
  MarkdownContent,
  DynamicForm,
  InternalLink,
  OutboundLink,
} from "@bluefin/components";
import { Grid, Segment, Checkbox } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class BeAModelPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      uxeatulpwmwvoydbhckhgbjykzpvmquhmswo,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.bellamiacademie.com/be-a-model/"}
        />
        <Grid
          className={"custom-page-model-request custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={""}
            style={{ padding: 10 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={""}
              style={{ padding: 10 }}
              width={10}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "e3e9c189-2054-4037-bbf4-3f5557fbcf66",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={""}
            style={{ padding: 10 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={""}
              style={{ padding: 10 }}
              width={10}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={1399}
                  businessInfoId={6579}
                  websiteId={7642}
                  locationId={1470}
                  componentConfigurationId={44710}
                  header={uxeatulpwmwvoydbhckhgbjykzpvmquhmswo.data.header}
                  fields={
                    uxeatulpwmwvoydbhckhgbjykzpvmquhmswo.data.dynamicFormFields
                  }
                  consentSettings={
                    uxeatulpwmwvoydbhckhgbjykzpvmquhmswo.data.consentSettings
                  }
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"cpwclhkogwtwcgty"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"imnzjzfqpqbpidqi"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__a9f932de-6e75-4d32-91df-4b85b38e87ab"}
                >
                  <div className={"custom-tos-container"}>
                    <div>
                      <Checkbox
                        label={
                          "I consent to receive sms notifications, alerts from BellaMi Academie. Message frequency varies. Message & data rates may apply. Text HELP to (833) 235-5264 for assistance. You can reply STOP to unsubscribe at any time."
                        }
                      />
                      <Checkbox
                        label={
                          "By checking this box I agree to receive occasional marketing messages from BellaMi Academie."
                        }
                      />
                    </div>
                    <InternalLink to={"/terms-and-conditions/"}>
                      Terms of Service
                    </InternalLink>
                    <span>&nbsp;& &nbsp;</span>
                    <OutboundLink
                      to={
                        "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                      }
                    >
                      Privacy Policy
                    </OutboundLink>
                  </div>
                </React.Fragment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 23804 }) {
      title
      seoTitle
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
    uxeatulpwmwvoydbhckhgbjykzpvmquhmswo: fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "b26cd018-d932-4412-bf69-95256312c972" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
          sendEmailToSelectedLocation
        }
        consentSettings {
          show
          required
        }
      }
    }
  }
`;
